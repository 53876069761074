import useTranslation from 'next-translate/useTranslation'
import { useState, useEffect, FC } from 'react'
import { Translate } from 'next-translate'
import {
  baseValidator,
  emailValidator,
  numberGreaterThanZeroValidator
} from '@/core/validationUtils'
import { Box, Grid } from '@/components/atoms/Grid'
import styled from 'styled-components'
import { AccentButton } from '@/components/atoms/button'
import {
  getValidationResult,
  initData,
  isFormValid,
  onInputChange
} from '@/components/molecules/form/form'
import { Input, InputValue } from '@/components/molecules/form/form'
import FormElement from '@/components/molecules/form/formElement'
import FormInput from '@/components/molecules/form/formInput'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import { useUser } from '@/providers/userProvider'
import { Customer, Sdk } from '@/network/graphql.g'
import useGraphQl from 'hooks/useGraphQl'
import { makeLoginRouterWithReferrer } from '@/utils/routerHelpers'
import { is401, isOk } from '@/utils/graphQl'

const makeEmailField = (t: Translate): Input => ({
  title: t('Return.Form.email'),
  name: 'email',
  rules: [
    {
      isValid: baseValidator,
      errorMessage: t('Return.Form.emptyEmailErrorPlaceholder')
    },
    {
      isValid: emailValidator,
      errorMessage: t('Return.Form.notValidEmailErrorPlaceholder')
    }
  ]
})

const makeOrderNumberField = (t: Translate): Input => ({
  title: t('Return.Form.orderNumber'),
  name: 'orderNumber',
  rules: [
    {
      isValid: baseValidator,
      errorMessage: t('Return.Form.emptyOrderNumberErrorPlaceholder')
    },
    {
      isValid: numberGreaterThanZeroValidator,
      errorMessage: t('Return.Form.notValidOrderNumberErrorPlaceholder')
    }
  ]
})

const makeReturnFormFields = (t: Translate, user: Customer): Input[] => {
  const fields = [makeOrderNumberField(t)]
  !user && fields.push(makeEmailField(t))
  return fields
}

export enum ReturnType {
  Return = 'return',
  Reclaim = 'reclaim'
}

type ReturnFormProps = {
  returnBtn: boolean
  reclaimBtn: boolean
}

const ReturnForm: FC<ReturnFormProps> = ({
  children,
  reclaimBtn,
  returnBtn,
  ...rest
}) => {
  const { t } = useTranslation('common')

  const router = useRouter()
  const [showValidation, setShowValidation] = useState<boolean>(false)
  const [data, setData] = useState<Record<string, InputValue>>({})
  const { user } = useUser()
  const formFields = makeReturnFormFields(t, user)
  const { requestNotifyError } = useGraphQl()

  useEffect(() => {
    initData(formFields, setData)
  }, [])

  const validate = (): boolean => {
    setShowValidation(true)
    return isFormValid(data)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    validate() && send()
  }

  const send = async () => {
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.canReturn,
      variables: {
        orderId: data.orderNumber?.value,
        email: data.email?.value
      },
      successPredicate: (response) =>
        isOk(response?.checkDataForReturn?.responseCode) ||
        is401(response?.checkDataForReturn?.responseCode)
    }))
    if (result.success) {
      const url = `${`${Routes.returnAdd}/${data.orderNumber.value}/${
        data.email?.value ?? ''
      }`.replace(/\/$/, '')}?${'type='}${
        returnBtn ? ReturnType.Return : reclaimBtn ? ReturnType.Reclaim : ''
      }`.replace(/\?type=$/, '')
      if (isOk(result.response?.checkDataForReturn?.responseCode)) {
        router.push(url)
      } else if (is401(result.response?.checkDataForReturn?.responseCode)) {
        router.push(makeLoginRouterWithReferrer(url))
      }
    }
  }

  return (
    <Box>
      <Grid
        gridTemplateColumns="1fr"
        gridRowGap={'16px'}
        gridColumnGap={'16px'}
        {...rest}
      >
        {formFields.map(
          ({ name, title, placeholder, rules, type, ...rest }) => {
            const { isValid, errorMessage } = getValidationResult(
              data[name],
              rules
            )
            return (
              <Box key={name} {...rest}>
                <FormElement showValidation={showValidation} isValid={isValid}>
                  <FormInput
                    name={name}
                    value={data[name]?.value}
                    placeholder={title || placeholder}
                    onChange={(name, value) =>
                      onInputChange(name, value, rules, setData)
                    }
                    formInputType={type}
                    isError={showValidation && !isValid}
                    adjustPlaceholderPosition
                    errorPlaceholder={errorMessage}
                  />
                </FormElement>
              </Box>
            )
          }
        )}
      </Grid>
      {children}
      <Box textAlign="right">
        <SubmitButton title={t('Return.Form.submit')} onClick={onSubmit} />
      </Box>
    </Box>
  )
}

export default ReturnForm

const SubmitButton = styled(AccentButton)`
  text-transform: uppercase;
`
