import { FC } from 'react'
import { SvgProps } from './svgProps'

const ReclaimIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    width={width || 24}
    height={height || 22}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 464.000000 405.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,405.000000) scale(0.100000,-0.100000)"
      fill={color || '#000'}
    >
      <path
        d="M2177 3810 c-544 -48 -1016 -326 -1315 -771 -63 -95 -132 -230 -132
-259 0 -60 51 -115 119 -131 61 -14 114 17 149 88 95 186 295 416 462 530 395
268 887 344 1320 203 291 -95 550 -299 743 -587 70 -104 173 -312 216 -436
l31 -89 -24 19 c-14 10 -50 38 -80 62 -96 75 -185 64 -234 -30 -25 -47 -24
-85 3 -125 14 -22 363 -325 428 -371 40 -29 118 -31 163 -4 35 22 346 430 369
485 45 107 -87 217 -194 162 -15 -8 -56 -51 -90 -95 -59 -77 -81 -91 -81 -53
0 9 -13 58 -29 107 -222 700 -744 1177 -1401 1279 -127 20 -303 26 -423 16z"
      />
      <path
        d="M621 2147 c-21 -10 -354 -425 -379 -474 -47 -89 41 -199 151 -190 57
5 72 17 144 121 l56 78 33 -113 c161 -561 541 -998 1042 -1204 384 -157 828
-165 1239 -24 406 140 761 444 963 824 54 103 32 193 -57 231 -84 36 -131 9
-198 -115 -70 -129 -156 -240 -273 -353 -218 -209 -475 -338 -777 -390 -155
-26 -428 -21 -570 10 -479 108 -864 462 -1068 982 -69 175 -69 172 -8 120 89
-78 111 -90 156 -90 106 0 185 136 124 214 -11 14 -102 92 -202 172 -100 81
-188 154 -194 162 -39 48 -125 67 -182 39z"
      />
    </g>
  </svg>
)

export default ReclaimIcon
