import styled from 'styled-components'
import { H3, Tiny2 } from '@/components/atoms/typography'
import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import ErrorText from '@/components/atoms/errorText'

export enum FormElementErrorPosition {
  RIGHT_BOTTOM,
  BETWEEN_TITLE_INPUT
}

type FormElementProps = {
  label?: string
  showValidation: boolean
  isValid: boolean
  errorPosition?: FormElementErrorPosition
  errorPlaceholder?: string
  highlightTitle?: boolean
}

const FormElement: FC<FormElementProps> = ({
  label,
  showValidation,
  isValid,
  errorPlaceholder = '',
  errorPosition = FormElementErrorPosition.RIGHT_BOTTOM,
  highlightTitle = false,
  children
}) => {
  const ErrorLabel =
    showValidation && errorPlaceholder && errorPlaceholder.length > 0 ? (
      <ErrorText
        textAlign={
          errorPosition === FormElementErrorPosition.RIGHT_BOTTOM
            ? 'end'
            : 'start'
        }
        mb={FormElementErrorPosition.RIGHT_BOTTOM ? 0 : '6px'}
        opacity={showValidation && !isValid && errorPlaceholder ? 1 : 0}
        data-cy="errorText"
      >
        {errorPlaceholder}
      </ErrorText>
    ) : null

  return (
    <Flex
      flexDirection="column"
      mb={FormElementErrorPosition.RIGHT_BOTTOM ? 0 : 10}
    >
      {label &&
        (highlightTitle ? (
          <H3 mt="3px" mb={10}>
            {label}
          </H3>
        ) : (
          <StyledTiny>{label}</StyledTiny>
        ))}
      {errorPosition === FormElementErrorPosition.BETWEEN_TITLE_INPUT &&
        ErrorLabel}
      {children}
      {errorPosition === FormElementErrorPosition.RIGHT_BOTTOM && ErrorLabel}
    </Flex>
  )
}

export default FormElement

const StyledTiny = styled(Tiny2)`
  margin-top: 3px;
  margin-bottom: 10px;
`
