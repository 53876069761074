import { Box } from '@/components/atoms/Grid'
import { FC } from 'react'

type InformationContentProps = {
  html: string
} & Rest

const InformationContent: FC<InformationContentProps> = ({ html, ...rest }) => {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: html
      }}
      {...rest}
    />
  )
}

export default InformationContent
