import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js'
import IBAN from 'iban'
import cardValid from 'card-validator'

export const baseValidator = (value?: string): boolean =>
  value ? value.length > 0 : false

export const rangeLengthValidator = (
  min?: number,
  max?: number,
  value?: string
): boolean =>
  value ? (!min || value.length >= min) && (!max || value.length <= max) : false

export const numberValidator = (value?: string): boolean =>
  value ? !isNaN(Number(value)) : false

export const numberGreaterThanZeroValidator = (value?: string): boolean =>
  numberValidator(value) && Number(value) > 0

export const newEmailValidator = (value?: string): boolean => {
  const mailPattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  const enabledGmailAddress = ['.com', '.hu']

  const gmailPattern = new RegExp(
    '@gmail\\.(' +
      enabledGmailAddress.map((suffix) => suffix.replace('.', '')).join('|') +
      ')$',
    'i'
  )

  if (value && value.includes('@gmail') && !gmailPattern.test(value)) {
    return false
  }

  return mailPattern.test(value ? value : '')
}

export const emailValidator = (value?: string): boolean => {
  const mailPattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return mailPattern.test(value ? value : '')
}

export const VATIdValidator = (value: string): boolean => {
  if (!value) {
    return true
  }
  const uniValue = (value ?? '').replaceAll(/[^0-9A-Z]/g, '')
  let pattern
  switch (uniValue.slice(0, 2)) {
    case 'BG':
      pattern = '\\d{9,10}'
      break
    case 'CZ':
      pattern = '\\d{8,10}'
      break
    case 'EE':
    case 'EL':
      pattern = '\\d{9}'
      break
    case 'ES':
      pattern = '\\w{9}'
      break
    case 'HR':
    case 'IT':
    case 'LV':
      pattern = '\\d{11}'
      break
    case 'HU':
    case 'SI':
      pattern = '\\d{8}'
      break
    case 'LT':
      pattern = '(\\d{9}|\\d{12})'
      break
    case 'PL':
    case 'SK':
      pattern = '\\d{10}'
      break
    case 'RO':
      pattern = '\\d{2,10}'
      break
    default:
      pattern = '\\d+'
      break
  }
  const finalValidation = new RegExp(`^[A-Z]{2}${pattern}$`)
  return finalValidation.test(uniValue ? uniValue : '')
}

export const phoneValidator = (
  countryCode: string,
  value?: string
): boolean => {
  return value
    ? isValidPhoneNumber(value, countryCode.toUpperCase() as CountryCode)
    : false
}

export const ibanValidator = (value?: string): boolean => {
  return value ? IBAN.isValid(value) : false
}

export const bbanValidator = (value?: string): boolean => {
  if (!value || ibanValidator(value)) {
    return false
  }
  const trimmedBankAccount = (value ?? '').replaceAll(/[^0-9]/g, '')
  if (trimmedBankAccount.length !== 16 && trimmedBankAccount.length !== 24) {
    return false
  }
  return true
}

const CZ_BANK_CODE_LENGTH = 4
const CZ_ACCOUNT_MIN_LENGTH = 6

export const czBankAccountValidator = (value?: string): boolean => {
  if (!value || ibanValidator(value)) {
    return false
  }
  // Parse bank account (pozadovane formaty 000012-0001234567/1234 alebo 0001234567/1234)
  // overene v CNB : https://www.cnb.cz/cs/platebni-styk/iban/vzor-zadani-cisla-uctu-do-kalkulatoru/
  const trimmedBankAccount = (value ?? '').replaceAll(/[^0-9-/]/g, '')
  if (trimmedBankAccount.indexOf('/') !== -1) {
    const accountParts = trimmedBankAccount.split('/')
    if (
      accountParts.length === 2 &&
      accountParts[0].length >= CZ_ACCOUNT_MIN_LENGTH &&
      accountParts[1].length === CZ_BANK_CODE_LENGTH
    ) {
      return true
    }
  }
  return false
}

export const latinValidator = (value?: string): boolean => {
  const pattern = new RegExp('[\\p{Script=Cyrillic}]', 'u')
  return !pattern.test(value ? value : '')
}

export const cyrillicValidator = (value?: string): boolean => {
  const pattern = new RegExp('[\\p{Script=Latin}]', 'u')
  return !pattern.test(value ? value : '')
}

export const cardValidator = (value?: string): boolean =>
  cardValid.number(value).isValid
