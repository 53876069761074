import { MediumTinyBold } from '@/components/atoms/typography'
import styled from 'styled-components'

const StyledMediumTinyBold = styled(MediumTinyBold)`
  color: ${(props) => props.theme.colors.errorSecondary};
  min-height: 13px;
  margin: 0;
`

export default StyledMediumTinyBold
