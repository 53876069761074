import styled from 'styled-components'

export const InformationSection = styled.section`
  padding: 32px 0;
  & ul {
    margin-bottom: 16px;
  }
  & ul li {
    padding-bottom: 10px;
  }
`
