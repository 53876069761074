import { FormInputType } from './formInput'

export type Input = {
  title: string
  name: string
  placeholder?: string
  subTitle?: string
  initialValue?: string
  type?: FormInputType
  valueType?: string
  rules?: ValidationRule[]
} & Rest

export type ValidationRule = {
  isValid: (value: string) => boolean
  errorMessage: string
}

export type InputValue = {
  value?: string
  isValid: boolean
  errorMessage?: string
}

type ValidationResult = {
  isValid: boolean
  errorMessage?: string
}

export type FormInputValues = Record<string, InputValue>

const validateInput = (
  value: string,
  rules: ValidationRule[]
): ValidationResult => {
  if (rules) {
    const brokenRules = rules.filter((e) => !e.isValid(value))
    if (brokenRules.length > 0) {
      return { isValid: false, errorMessage: brokenRules[0].errorMessage }
    }
  }
  return { isValid: true }
}

export const getValidationResult = (
  inputValue: InputValue,
  rules: ValidationRule[]
): ValidationResult =>
  inputValue ? { ...inputValue } : validateInput(null, rules)

export type SetData = (
  data: (oldData: FormInputValues) => FormInputValues
) => void

export const onInputChange = (
  name: string,
  value: string,
  rules: ValidationRule[],
  setData: SetData
): void => {
  const { isValid, errorMessage } = validateInput(value, rules)
  setData((record) => ({
    ...record,
    [name]: {
      value: value,
      isValid: isValid,
      errorMessage: errorMessage
    }
  }))
}

export const initData = (inputs: Input[], setData: SetData): void => {
  setData((record) => ({ ...record, ...mapInputsToValues(inputs) }))
}

export const reinitData = (inputs: Input[], setData: SetData): void => {
  setData((record) => ({ ...mapInputsToValues(inputs, record) }))
}

export const mapInputsToValues = (
  inputs: Input[],
  prevData: FormInputValues = {}
): FormInputValues => {
  let values: FormInputValues = {}
  inputs.forEach((input) => {
    const { name, rules, initialValue } = input
    const value = prevData[name]?.value ?? initialValue
    const { isValid, errorMessage } = validateInput(value, rules)
    values = {
      ...values,
      [name]: {
        value: value,
        isValid,
        errorMessage
      }
    }
  })
  return values
}

export const isFormValid = (data: FormInputValues): boolean => {
  return Object.values(data).filter((e) => !e.isValid).length === 0
}
