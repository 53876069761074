import { Box, Flex } from '@/components/atoms/Grid'
import InformationContent from '@/components/atoms/informationContent'
import { InformationSection } from '@/components/atoms/informationSection'
import { H3, InformationH2 } from '@/components/atoms/typography'
import PageContentWrapper, {
  PageContentWrapperBackgroundStyle
} from '@/components/organisms/pageContentWrapper'
import ReturnForm from '@/components/organisms/returnForm'
import { Information, Page } from '@/network/graphql.g'
import { validFor } from '@/theme/validFor'
import useTranslation from 'next-translate/useTranslation'
import { InformationPage } from 'types/informationPage'
import { requestThrow401 } from '@/utils/graphQl'
import { withBaseServerSideProps } from '@/HOC/withBaseServerSideProps'
import { useMhub } from '@/providers/mhubProvider'
import { FC, useEffect, useState } from 'react'
import { IconButton } from '@/components/molecules/iconButtons'
import { transparentize } from 'polished'
import styled, { useTheme } from 'styled-components'
import { MarketCode } from '@/network/graphql.g'
import { useDomain } from '@/providers/storeProvider'
import { ReturnIconPlainDefault } from '@/components/atoms/icons/ReturnPlainIconDefault'
import { ReclaimIconDefault } from '@/components/atoms/icons/ReclaimIconDefault'
import { getMainData } from '@/utils/mainDataHelper'
import { useAsset } from '@/providers/assetsProvider'
import { useSpeedCurve } from '@/core/speedCurve/useSpeedCurve'

type ReturnProps = {
  information: Information
}

const Return: FC<ReturnProps> = ({ information }) => {
  const { t } = useTranslation('common')
  const [returnBtn, setReturnBtn] = useState<boolean>(false)
  const [reclaimBtn, setReclaimBtn] = useState<boolean>(false)
  const returnerBtn = () => {
    setReturnBtn((returnBtn) => !returnBtn)
    setReclaimBtn(false)
  }
  const reclaimerBtn = () => {
    setReclaimBtn((reclaimBtn) => !reclaimBtn)
    setReturnBtn(false)
  }

  const { pushReturnPage } = useMhub()
  useEffect(() => {
    pushReturnPage()
  }, [])

  const theme = useTheme()
  const { subdomainName } = useDomain()
  const isRomanian = MarketCode.Ro === subdomainName
  const { isShutDownCountry } = useAsset()
  const { domainName } = useDomain()

  const originalShutDownMessage = t('Return.marketDisabledText', {
    subdomain: domainName.charAt(0).toUpperCase() + domainName.slice(1)
  })

  const contactEmail = `${domainName}-${subdomainName}@${domainName}.com`

  const formattedShutDownMessage = originalShutDownMessage
    .replace(/\\n/g, '<br></br>')
    .replace('{{email}}', contactEmail)

  useSpeedCurve({
    label: 'Return'
  })

  return (
    <PageContentWrapper
      backgroundStyle={PageContentWrapperBackgroundStyle.normal}
    >
      {isShutDownCountry ? (
        <Box
          mb={4}
          mt={{ mobile: '16px', tablet: '16px', desktop: '0' }}
          dangerouslySetInnerHTML={{
            __html: formattedShutDownMessage
          }}
        />
      ) : null}
      <InformationH2>{t('Return.title')}</InformationH2>

      <Box className="information-page">
        <InformationContent html={information.description.description} />
      </Box>
      <InformationSection>
        <StyledH3>{t('Return.title')}</StyledH3>
        {isRomanian && (
          <Flex justifyContent="flex-start">
            <ReturnIconButton
              title={t('Return.Form.return')}
              onClick={returnerBtn}
              returnBtn={returnBtn}
              IconElement={
                <ReturnIconPlainDefault
                  color={returnBtn && theme.colors.menuBackground}
                />
              }
            />
            <ReclaimIconButton
              title={t('Return.Form.reclaim')}
              onClick={reclaimerBtn}
              reclaimBtn={reclaimBtn}
              IconElement={
                <ReclaimIconDefault
                  color={reclaimBtn && theme.colors.menuBackground}
                />
              }
            />
          </Flex>
        )}
        {(!isRomanian || returnBtn || reclaimBtn) && (
          <ReturnForm returnBtn={returnBtn} reclaimBtn={reclaimBtn} />
        )}
      </InformationSection>
    </PageContentWrapper>
  )
}

export default Return

export const getServerSideProps = withBaseServerSideProps(
  async ({
    sdk,
    withMainNavigation,
    withStore
  }): Promise<GetServerSideProps<ReturnProps>> => {
    const { success, response } = await requestThrow401({
      method: sdk.getInformation,
      variables: {
        id: InformationPage.Returns,
        page: Page.Return,
        withMainNavigation,
        withStore
      },
      mainResponse: (response) => response?.information
    })
    if (!success) {
      return {
        notFound: true
      }
    }

    return {
      props: {
        mainData: getMainData(response),
        seoTags: response?.seoTags,
        information: response?.information
      }
    }
  }
)

const StyledH3 = styled(H3)`
  ${validFor.tablet`
    font-size: 16px;
  `}
  ${validFor.mobile`
    font-size: 16px;
  `}
`

const ReturnIconButton = styled(IconButton)`
  padding: 5px 20px;
  margin: 5px 40px 15px 0px;
  background-color: ${({ returnBtn, theme }) =>
    returnBtn
      ? theme.colors.onBackground
      : theme.colors.menuItemBackgroundSecondary};
  border: none;
  color: ${({ returnBtn, theme }) => returnBtn && theme.colors.background};
  &:hover {
    background-color: ${({ returnBtn, theme }) =>
      returnBtn
        ? transparentize(0.2, theme.colors.onBackground)
        : transparentize(0.2, theme.colors.menuItemBackgroundSecondary)};
  }
`

const ReclaimIconButton = styled(IconButton)`
  padding: 5px 20px;
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: ${({ reclaimBtn, theme }) =>
    reclaimBtn
      ? theme.colors.onBackground
      : theme.colors.menuItemBackgroundSecondary};
  border: none;
  color: ${({ reclaimBtn, theme }) => reclaimBtn && theme.colors.background};
  &:hover {
    background-color: ${({ reclaimBtn, theme }) =>
      reclaimBtn
        ? transparentize(0.2, theme.colors.onBackground)
        : transparentize(0.2, theme.colors.menuItemBackgroundSecondary)};
  }
`
